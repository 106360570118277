import Heading from 'firebird-ui/src/components/Heading'
import styled from 'styled-components'

import Button from '@base/buttons/Button'

export const MobileAppDownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 46px;
  width: 100%;

  ${p => p.theme.mediaQueries.mobileTablet} {
    flex-direction: column;
    row-gap: 24px;
  }
`

export const Title = styled(Heading)`
  align-self: start;
  &,
  * {
    color: ${p => p.theme.colors.white};
  }
`

export const MobileAppDownloadLinksWrapper = styled.div`
  display: flex;
  column-gap: 24px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    width: 100%;
  }
`

export const LinkDownload = styled(Button)`
  &.ant-btn {
    width: 240px;
    height: 64px;
    padding: 0;
    border-radius: ${p => p.theme.borderRadius.sm};

    ${p => p.theme.mediaQueries.desktopXS} {
      width: 100%;
    }

    &,
    &:active,
    &:not(:disabled):not(.ant-btn-disabled):active,
    &:focus,
    &:not(:disabled):not(.ant-btn-disabled):focus,
    &:hover,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${props => props.theme.colors.whiteOpacity10};
    }

    span {
      color: inherit;
    }
  }
`
