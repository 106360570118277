import Head from 'next/head'
import type { FC, ReactNode } from 'react'

import type { HomePageEntityType } from '@redux/features/homePage/types/blocksHomePage'

import type { MetaTag, MetaTagsType } from '@Types/common/metaTags'
import isProd from '@constants/env/isProd'
import { type DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'

export type MetaDataProps = DefaultServerSidePropsType & {
  children?: ReactNode
  metaTags?: HomePageEntityType['field_metatags'] | null
  metaTagsArray?: MetaTagsType | null
  noIndex?: boolean
  schemaOrgScriptData?: string
}

const MetaData: FC<MetaDataProps> = ({
  canonicalUrl,
  children,
  metaTags,
  metaTagsArray,
  noIndex,
  schemaOrgScriptData,
}) => {
  const title =
    (metaTagsArray?.find(({ attributes, tag }) => tag === 'meta' && attributes.name === 'title') as MetaTag)?.attributes
      ?.content || metaTags?.title

  return (
    <Head>
      <title>{title}</title>
      {metaTags &&
        !metaTagsArray &&
        Object.entries(metaTags).map(
          ([name, content]) => name !== 'title' && <meta content={content} key={name} name={name} />
        )}
      {metaTagsArray &&
        metaTagsArray.map(({ attributes, tag }, key) => {
          if (tag !== 'meta' || attributes.name === 'title') return
          return <meta {...attributes} key={key} />
        })}
      {(noIndex || !isProd) && <meta content="noindex, nofollow" name="robots" />}
      <link href={canonicalUrl} rel="canonical" />
      {schemaOrgScriptData && (
        <script
          dangerouslySetInnerHTML={{
            __html: schemaOrgScriptData,
          }}
          type="application/ld+json"
        />
      )}
      {children}
    </Head>
  )
}

export default MetaData
